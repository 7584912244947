<script>
  import { onMount } from "svelte";

  let lastUpdated = "";
  let time = "";
  let timeZone = "";

  let utmSource = "";
  let utmMedium = "";
  let utmCampaign = "";

  let showModal = false;

  const slogans = [
    "In a world full of bugs, be the patch.",
    "In a sea of errors, be the fix.",
    "In the code of life, be the clean syntax.",
    "When the system crashes, be the restart.",
    "In a world of 404s, be the solution.",
    "Hello world, goodbye bugs.",
    "When everything’s broken, be the refactor.",
    "When the code gets tangled, be the untangler.",
    "In the debug process, be the solution.",
    "Code hard, debug harder.",
    "Code like nobody's watching.",
    "Run the code, break the rules.",
    "When complexity rises, be the simplicity.",
    "When the code is down, be the patch.",
    "In a world of crashes, be the recovery.",
    "In a universe of bugs, be the debugger.",
    "In the chaos of code, be the comment.",
    "In a field of errors, be the exception.",
    "In a world of stack traces, be the stack overflow.",
    "When the code runs wild, be the control.",
    "Push to prod, pray for no bugs.",
    "Error 404: Boring slogans not found.",
    "Hack the planet, fix the bugs."
  ];

  let currentSlogan = "";

  const updateUTMParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    utmSource = urlParams.get("utm_source") || "No source";
    utmMedium = urlParams.get("utm_medium") || "No medium";
    utmCampaign = urlParams.get("utm_campaign") || "No campaign";
  };

  const updateBinaryCode = () => {
    // Generate a binary string with 6 random bits (e.g., "101011")
    lastUpdated = Array.from({ length: 6 }, () =>
      Math.floor(Math.random() * 2)
    ).join("");
  };

  const updateTime = () => {
    const now = new Date();

    const options = {
      timeZone: "Europe/Amsterdam",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZoneName: "short"
    };

    const timeWithZone = now.toLocaleString("en-US", options);

    const [timePart, zonePart] = timeWithZone.split(" ");

    time = timePart;
    timeZone = zonePart;
  };

  setInterval(() => {
    updateBinaryCode();
    updateTime();
  }, 200);

  onMount(() => {
    updateUTMParams();
    currentSlogan = slogans[Math.floor(Math.random() * slogans.length)];
  });

  $: updateUTMParams();

  const openModal = () => {
    showModal = true;
  };

  const closeModal = () => {
    showModal = false;
  };
</script>

<main>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="overlay"
    on:click={closeModal}
    style:display={showModal ? "block" : "none"}
  ></div>

  <header>
    <div class="menu">
      <!-- svelte-ignore missing-declaration -->
      <img src="/img/logo_10110.svg" alt="Logo 10110" class="logo" />
    </div>

    <h1 class="name">Code. Create. Conquer.</h1>

    <div class="title">
      <div class="desc">
        <!-- svelte-ignore missing-declaration -->
        <h2 class="slogan">{currentSlogan}</h2>
        <time class="status-time monospace">{lastUpdated}</time>
      </div>
      <p class="pronouns">[He/Him/His]</p>
    </div>
  </header>
  <section>
    <p class="intro">
      Welcome! If you're here, chances are you're ready to elevate your online
      presence — and you're in the right place. This domain was carefully
      selected with one clear mission: to help Persian artists like you leave a
      lasting impact on the digital world through culturally resonant and
      visually stunning websites.
    </p>

    <p class="intro">
      As a software designer and engineer, I develop simple yet powerful
      websites that deliver exceptional user experiences. A member of WordPress
      Core and participate in the
      <a
        href="https://infrequently.org/stickers/"
        target="_blank"
        rel="nofollow">Anti JavaScript JavaScript Club</a
      >, pioneering innovative web development methodologies. Additionally, I
      share my knowledge through teaching at Fontys University of Applied
      Sciences. With a deep passion for the open web, web standards, and
      accessibility, I am dedicated to transforming your vision into a dynamic
      online presence.
    </p>

    <p>
      Currently based in The Netherlands where it's <span
        class="time"
        data-timestamp-text=", where it's currently {time}"
      >
        {time.slice(0, 2)}<span class="blink">:</span>{time.slice(3)}
        {timeZone}
      </span>
    </p>

    <div class="code-container">
      <p>My goal is simple: to build a web that works for everyone.</p>
    </div>
    <p>
      Feel free to reach out if you're an artist looking to bring Persian art
      and culture into the digital world—or if you need any assistance with the
      domain. I’m here to help! Together, we can blend timeless beauty with
      innovation and create something unforgettable.
    </p>
    <p>
      For work-related inquiries, ideas, or collaboration, get in touch at <code
        >&lt;info at 10110 dot dev&gt;</code
      >.
    </p>
  </section>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <img
    id="openModalBtn"
    src="/img/farsi_speaking_icon.svg"
    alt="Farsi icon"
    class="floating-image animate"
    on:click={openModal}
  />

  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <img
    id="openModalBtn"
    src="/img/farsi_speaking_icon_mobile.svg"
    alt="Farsi icon"
    class="floating-image-mobile animate"
    on:click={openModal}
  />

  <!-- The Modal -->
  {#if showModal}
    <div id="myModal" class="modal">
      <div class="modal-content">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <span class="close-btn" on:click={closeModal}>&times;</span>
        <section>
          <p class="persian">
            برای درخواست‌های کاری، ایده‌ها یا همکاری، با من از طریق <code
              >&lt;info at 10110 dot dev&gt;</code
            > تماس بگیرید.
          </p>
          <a href="mailto:info@10110.dev " target="_blank" class="modal-button"
            >درخواست هماهنگی</a
          >
        </section>
      </div>
    </div>
  {/if}

  <div class="version">
    <a href="https://10110.dev" target="_blank">v.1.0</a>
  </div>
</main>

<style>
  :global(body) {
    margin: 0;
    padding: 0;
    background-color: var(--backgroundColor);
    color: var(--textColor);
    font-size: 1.2em;
    font-family: var(--sansSerif);
    line-height: 1.62em;
    word-wrap: break-word;
  }

  header {
    margin-bottom: 2rem;
  }

  .menu {
    display: flex;
    justify-content: space-between;
  }

  .pronouns {
    font-weight: 300;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(2px);
    z-index: 9998;
  }

  .floating-image {
    display: block;
    position: fixed;
    cursor: pointer;
    width: 55px;
    height: auto;
    z-index: 999;
    transform: translateY(-50%);
    top: 20%;
    left: 0;
    scale: 1;
    transition: scale 0.3s ease;
  }

  .floating-image-mobile {
    display: none;
  }

  .floating-image:hover,
  .floating-image-mobile:hover {
    scale: 1.1;
  }

  .animate {
    animation: AnimatieKeyframe 3s ease-in-out infinite;
  }

  @keyframes AnimatieKeyframe {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--backgroundColor);
    color: white;
    padding: 20px;
    border-radius: 10px;
    z-index: 9999;
    max-width: 350px;
    width: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .modal-content {
    margin: auto;
    padding: 10px;
    max-width: 500px;
    text-align: right;
  }

  .modal-button {
    display: block;
    background-color: #40e878;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    text-align: center;
    font-family: "Vazir";
    margin-top: 20px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }

  .modal-button:hover {
    background-color: #2dcf63;
  }

  .close-btn {
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 25px;
    cursor: pointer;
  }

  .close-btn:hover,
  .close-btn:focus {
    color: #f14b4b;
    text-decoration: none;
    cursor: pointer;
  }

  .persian {
    font-weight: 400;
    margin: 0;
    direction: rtl;
    font-family: "Vazir";
    font-size: 1.2rem;
  }

  section {
    margin-top: 20px;
  }

  code {
    color: var(--activeColor);
  }

  .name {
    font-family: var(--loRes);
    font-size: 2.5rem;
    margin: 0 0 3rem 0;
  }

  .title {
    display: flex;
    justify-content: space-between;
  }

  .title p {
    color: var(--textColor);
    font-size: 0.75rem;
    font-weight: 400;
    margin: 0;
  }

  .slogan {
    color: var(--mutedTextColor);
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
  }

  .intro {
    color: var(--textColor);
    margin: 0 0 10px 0;
  }

  .logo {
    width: 10rem;
    margin-bottom: 1rem;
  }

  .desc {
    display: flex;
    justify-content: space-between;
    font-size: 0.75em;
    line-height: 1.5;
    color: var(--textColor);
  }

  time.monospace {
    font-size: 0.7em;
    color: var(--mutedTextColor);
  }

  .time {
    color: var(--activeColor);
    background-color: var(--frameBackgroundColor);
    padding: 5px 9px;
    border-radius: 10px;
  }

  .blink {
    animation: blink 2s step-start 0s infinite;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }

  .status-time {
    margin-left: 0.7em;
  }

  .code-container {
    display: flex;
    max-width: 50vw;
    padding: 0 20px;
    margin: 20px auto;
    background-color: var(--frameBackgroundColor);
    position: relative;
    overflow: hidden;
    border-radius: 20px;
  }

  .code-container {
    font-family: var(--loRes);
    font-weight: 400;
  }

  code {
    font-family: var(--loRes);
    font-weight: 400;
  }

  .version {
    text-align: right;
    font-size: 0.6em;
  }

  .version a {
    font-size: 0.6rem;
    color: var(--textColor);
    text-decoration: none;
    background-color: #2d3947;
    padding: 3px 7px;
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    .code-container {
      max-width: 85vw;
    }

    .modal {
      max-width: 300px;
    }
    .floating-image {
      display: none;
    }

    .floating-image-mobile {
      display: block;
      position: fixed;
      cursor: pointer;
      width: 55px;
      height: auto;
      z-index: 999;
      transform: translateY(-50%);
      top: 2.5rem;
      right: 0;
      scale: 1;
      transition: scale 0.3s ease;
    }

    .pronouns {
      display: none;
    }
  }
</style>

<script>
  export let showModal = false;
  export let onAccept = () => {};

  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    onAccept();
    showModal = false;
  };

  if (localStorage.getItem("cookiesAccepted")) {
    showModal = false;
  }
</script>

{#if showModal}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="overlay" on:click={() => (showModal = false)}></div>

  <div
    class="cookie-modal"
    role="dialog"
    aria-labelledby="cookie-modal-title"
    aria-describedby="cookie-modal-description"
  >
    <p id="cookie-modal-description">
      We use cookies to improve your experience. By continuing to use this site,
      you agree to our use of cookies.
    </p>
    <button id="cookie-modal-accept" on:click={acceptCookies}>Got it!</button>
  </div>
{/if}

<style>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(2px); */
    z-index: 9998;
  }

  .cookie-modal {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background: #1b1f23;
    color: white;
    padding: 20px;
    border-radius: 10px;
    z-index: 9999;
    font-size: 1rem;
    max-width: 220px;
    width: 100%;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3); */
    backdrop-filter: blur(4px);
    background: rgba(27, 31, 35, 0.8);
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .cookie-modal button:focus {
    outline: 2px solid #6a57e2;
  }

  .cookie-modal button {
    background-color: #8876ff;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

    transition: background-color 0.3s ease;
  }

  .cookie-modal button:hover {
    background-color: #6a57e2;
  }

  #cookie-modal-description {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    .cookie-modal {
      max-width: 300px;
    }
  }
</style>

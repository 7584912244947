<script>
  import { onMount } from "svelte";
  import { Router, Route } from "svelte-routing";
  import Home from "./Home.svelte";
  import CookieConsent from "./CookieConsent.svelte";

  let showModal = false;

  // Functie om te controleren of cookies zijn geaccepteerd
  const checkCookies = () => {
    const consentGiven = localStorage.getItem("cookieConsent");
    if (!consentGiven) {
      showModal = true; // Toon de modal als cookies nog niet zijn geaccepteerd
    }
  };

  // Functie die wordt uitgevoerd als de gebruiker cookies accepteert
  const acceptCookies = () => {
    localStorage.setItem("cookieConsent", "true"); // Sla de keuze op in localStorage
    showModal = false; // Sluit de modal
    console.log("User accepted cookies!");
  };

  onMount(() => {
    checkCookies();
  });
</script>

<main>
  <Router>
    <Route path="*" component={Home} />
  </Router>

  <!-- Cookie Consent Modal component -->
  <CookieConsent {showModal} onAccept={acceptCookies} />
</main>

<style>
  :global(body) {
    margin: 0;
    padding: 0;
    background-color: var(--backgroundColor);
    color: var(--textColor);
    font-size: 1.2em;
    font-family: var(--sansSerif);
    line-height: 1.62em;
    word-wrap: break-word;
  }

  main {
    max-width: 50vw;
    margin: 50px auto 20px auto;
  }

  @media (max-width: 768px) {
    main {
      max-width: 85vw;
      margin: 50px auto;
    }
  }
</style>
